.b-anchors {
	margin: 0 auto;
	max-width: 940px;
	text-align: center;

	@include media(md-down) {
		text-align: left;
	}

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 15px;

		@include media(lg-up) {
			margin-bottom: 20px;
		}
	}

	&-subtitle {
		font-size: 13px;
		margin-bottom: 15px;

		@include media(lg-up) {
			margin: 0 auto 25px;
			max-width: 600px;
		}
	}

	&.m-left &-subtitle {
		@include media(lg-up) {
			margin: 0 0 25px;
		}
	}

	&-list {
		@include media(md-down) {
			margin: 0 -8px;
		}
	}

	&.m-left &-list {
		margin: 0 -8px;
	}

	&-item {
		align-items: center;
		display: inline-flex;
		font-size: 15px;
		line-height: 1;
		margin: 8px;
	}

	&.m-left {
		text-align: left;
	}
}
