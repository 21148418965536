.b-club_subscribe {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include media(lg-up) {
		margin-top: 70px;
	}

	&-title {
		color: $color-title;
		font-size: 20px;
		font-weight: 700;
		line-height: 1.25;
		margin: 25px auto 50px;
		text-align: center;
	}

	&-button {
		margin-bottom: 30px;
	}
}
