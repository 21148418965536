.b-category_item {
	align-items: center;
	color: $color-white;
	display: flex;
	flex-direction: column;
	font-size: 15px;
	justify-content: space-evenly;
	line-height: 15px;
	min-height: 90px;
	min-width: 100px;
	padding: 0 5px;
	text-align: center;

	&-img {
		display: block;
		filter: brightness(0) saturate(100%) invert(96%) sepia(79%) saturate(3427%) hue-rotate(321deg) brightness(100%) contrast(92%);
		margin: 0 auto 3px;
	}

	&-title {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: 30px;
	}
}
