.l-static_page {
	@include g-section_holder;

	color: adjust-color-to-bg($color-bg);
	margin-bottom: 40px;

	@include media(lg-up) {
		margin-bottom: 80px;
	}

	&-title {
		@include g-heading_4;

		color: $color-action-obj;

		@include media(lg-up) {
			font-size: 32px;
		}
	}

	&-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&-nav {
		padding: 0;
		width: 100%;

		@include media(lg-up) {
			flex-basis: grid-span(3, 'lg');
		}
	}

	&-main {
		flex-basis: 100%;

		@include media(lg-up) {
			flex-basis: grid-span(8, 'lg');
		}
	}

	&.m-no_menu {
		margin-top: 10px;

		@include media(md-up) {
			margin-top: 16px;
		}
	}

	&.m-poq {
		margin-top: 25px;
	}
}
