.b-navigation_show {
	display: flex;
	justify-content: flex-start;
	margin: 0 0 25px;
	overflow: hidden;
	padding: 0;
	width: 100%;

	@include media(lg-up) {
		display: none;
	}

	&.m-account {
		align-items: center;
		border-bottom: $global-line solid $color-divider;
		color: $color-title;
		margin-bottom: 30px;
		padding-bottom: 10px;
	}

	svg {
		background-color: $color-bg;
		border-right: 2px solid $color-action-obj;
		box-sizing: content-box;
		height: 100%;
		padding: 0 12px 0 14px;
		transition: $motion-ease;
		transition-property: background-color, border-color;
	}

	&.m-account svg {
		border: 0;
		padding: 0 5px 0 0;
	}

	&-text {
		margin: 0 auto;
	}

	&.m-account &-text {
		font-size: 17px;
		font-weight: 600;
		margin: 0;
	}
}
