.b-customer_service_info {
	color: $color-primary;
	font-size: 15px;
	line-height: 1.5;

	&-title {
		color: $color-title;
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	&-item {
		margin-bottom: 20px;
	}

	&-link {
		@include g-link;

		font-weight: 600;
	}
}
