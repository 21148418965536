@charset "UTF-8";
/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 16px 9px),
		grid-margin:  ('xl': 88px, 'lg': 24px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Line clamping

This mixin is useful for truncated text

Here is a list of parameters you can use:

* lines - the number of rows to display

## Usage

```scss
.component {
	@include line-clamping($lines: 3);
}

*/
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link(inherit);
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-section_holder_footer

Since footer is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold footer of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-footer-inner {
	background: green;

	&-footer {
		@include g-section_holder_footer;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
/*md
@no-stat

# Header item

This mixin is useful for header items with icon

## Usage

```scss
.component {
	@include g-header_item();
}

*/
/*md
@no-stat

# Header item element in Hamburger Menu

## Usage

```scss
.component {
	@include g-header_item_menu;
}

*/
/*md

# g-title

Designed to provide same styles of title across different components.

## Usage

```scss
.component {
	@include g-title(true);
}
```

*/
/*md

# g-loader

Designed to provide same styles of loading indicator across different components.

## Usage

```scss
.component {
	@include g-loader;
}
```

*/
.l-static_page {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  color: #000000;
  margin-bottom: 40px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-static_page {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-static_page {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-static_page {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-static_page {
    margin-bottom: 80px;
  }
}
.l-static_page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
}
@media screen and (min-width: 1024px) {
  .l-static_page-title {
    font-size: 32px;
  }
}
.l-static_page-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.l-static_page-nav {
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .l-static_page-nav {
    flex-basis: 25%;
  }
}
.l-static_page-main {
  flex-basis: 100%;
}
@media screen and (min-width: 1024px) {
  .l-static_page-main {
    flex-basis: 66.6666666667%;
  }
}
.l-static_page.m-no_menu {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .l-static_page.m-no_menu {
    margin-top: 16px;
  }
}
.l-static_page.m-poq {
  margin-top: 25px;
}

.b-grid {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .b-grid {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-grid {
    grid-gap: 16px 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.b-grid-col_2 {
  grid-column: span 2;
}
.b-grid-col_3 {
  grid-column: span 3;
}
.b-grid-col_4 {
  grid-column: span 4;
}
.b-grid-col_5 {
  grid-column: span 5;
}
.b-grid-col_6 {
  grid-column: span 6;
}
.b-grid-col_7 {
  grid-column: span 7;
}
.b-grid-col_8 {
  grid-column: span 8;
}
.b-grid-col_9 {
  grid-column: span 9;
}
.b-grid-col_10 {
  grid-column: span 10;
}
.b-grid-col_11 {
  grid-column: span 11;
}
.b-grid-col_12 {
  grid-column: span 12;
}
@media screen and (min-width: 1367px) {
  .b-grid-col_2_xl {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_2_lg {
    grid-column: span 2;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_2_md {
    grid-column: span 2;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_2_sm {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_3_xl {
    grid-column: span 3;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_3_lg {
    grid-column: span 3;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_3_md {
    grid-column: span 3;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_3_sm {
    grid-column: span 3;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_4_xl {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_4_lg {
    grid-column: span 4;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_4_md {
    grid-column: span 4;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_4_sm {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_5_xl {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_5_lg {
    grid-column: span 5;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_5_md {
    grid-column: span 5;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_5_sm {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_6_xl {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_6_lg {
    grid-column: span 6;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_6_md {
    grid-column: span 6;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_6_sm {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_7_xl {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_7_lg {
    grid-column: span 7;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_7_md {
    grid-column: span 7;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_7_sm {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_8_xl {
    grid-column: span 8;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_8_lg {
    grid-column: span 8;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_8_md {
    grid-column: span 8;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_8_sm {
    grid-column: span 8;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_9_xl {
    grid-column: span 9;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_9_lg {
    grid-column: span 9;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_9_md {
    grid-column: span 9;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_9_sm {
    grid-column: span 9;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_10_xl {
    grid-column: span 10;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_10_lg {
    grid-column: span 10;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_10_md {
    grid-column: span 10;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_10_sm {
    grid-column: span 10;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_11_xl {
    grid-column: span 11;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_11_lg {
    grid-column: span 11;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_11_md {
    grid-column: span 11;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_11_sm {
    grid-column: span 11;
  }
}
@media screen and (min-width: 1367px) {
  .b-grid-col_12_xl {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-grid-col_12_lg {
    grid-column: span 12;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-grid-col_12_md {
    grid-column: span 12;
  }
}
@media screen and (max-width: 767px) {
  .b-grid-col_12_sm {
    grid-column: span 12;
  }
}

/*md

# Textarea

Default textarea element

## Default

```html_example
	<textarea
		id=""
		class="b-textarea m-valid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

## Invalid

```html_example
	<textarea
		id=""
		class="b-textarea m-invalid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

*/
.b-textarea {
  appearance: none;
  border: 1px solid #ACACAC;
  border-radius: 3px;
  box-shadow: none;
  color: #000000;
  cursor: text;
  display: block;
  font-family: inherit;
  font-size: 13px;
  max-width: 100%;
  min-height: 6em;
  min-width: 100%;
  padding: 10px;
  resize: none;
  transition: border-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-textarea:hover, .b-textarea:active, .b-textarea:focus {
  border-color: #45132C;
}
.b-textarea-container {
  position: relative;
}
.b-textarea-counter {
  bottom: 2px;
  color: #ACACAC;
  font-size: 11px;
  position: absolute;
  right: 9px;
  text-align: right;
}
.b-textarea-counter_value {
  padding-right: 4px;
  position: relative;
}
.b-textarea-counter_value::after {
  content: "/";
  padding-left: 1px;
  position: absolute;
}
.b-textarea.m-invalid {
  border-color: #A42015;
}
.b-textarea.m-no_resize {
  resize: none;
}
.b-textarea.m-disabled, .b-textarea[disabled] {
  background: #F2F2F2;
  cursor: default;
  pointer-events: none;
}

.b-measure_tips {
  align-items: center;
  display: flex;
}
@media screen and (max-width: 767px) {
  .b-measure_tips {
    flex-direction: column;
  }
}
.b-measure_tips-image_wrap {
  flex-shrink: 0;
  max-width: 210px;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-measure_tips-image_wrap {
    max-width: 212px;
  }
}
@media screen and (min-width: 1024px) {
  .b-measure_tips-image_wrap {
    max-width: 253px;
  }
}
.b-measure_tips-image {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 141.9047619048%;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-measure_tips-image {
    padding-bottom: 151.8867924528%;
  }
}
@media screen and (min-width: 1024px) {
  .b-measure_tips-image {
    padding-bottom: 150.1976284585%;
  }
}
.b-measure_tips-image img {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-measure_tips-list li {
  margin-bottom: 24px;
}
.b-measure_tips-list li:last-child {
  margin-bottom: 0;
}
.b-measure_tips-list_wrap {
  align-self: flex-start;
}
@media screen and (min-width: 768px) {
  .b-measure_tips-list_wrap {
    align-self: initial;
    margin-left: 20px;
    max-width: 560px;
  }
}

.b-customer_service_info {
  color: #2F2F2F;
  font-size: 15px;
  line-height: 1.5;
}
.b-customer_service_info-title {
  color: #45132C;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
}
.b-customer_service_info-item {
  margin-bottom: 20px;
}
.b-customer_service_info-link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}
@media not all and (pointer: coarse) {
  .b-customer_service_info-link:hover {
    color: #C23D74;
  }
}

.b-navigation_panel {
  padding: 0;
  pointer-events: auto;
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel {
    display: block;
    position: static;
    visibility: visible;
    z-index: initial;
  }
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel::after {
    display: none;
  }
}
.b-navigation_panel .b-dialog-close {
  top: 15px;
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel .b-dialog-close {
    display: none;
  }
}
.b-navigation_panel-inner {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-navigation_panel-inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-navigation_panel-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-navigation_panel-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .b-navigation_panel-inner {
    background-color: #FFFFFF;
    bottom: 0;
    height: 100%;
    left: 0;
    max-width: 310px;
    overflow-y: auto;
    padding-bottom: 20px;
    padding-top: 40px;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition-property: visibility, transform;
    visibility: hidden;
    width: 80vw;
    z-index: 11;
  }
  .b-navigation_panel-inner.m-closed {
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  }
  .b-navigation_panel-inner.m-active {
    box-shadow: 0 3px 15px hsla(0, 0%, 0%, 0.35);
    transform: translateX(0);
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    visibility: visible;
  }
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel-inner {
    padding: 0;
  }
}
.b-navigation_panel-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  display: block;
  margin-bottom: 30px;
}
@media screen and (max-width: 1023px) {
  .b-navigation_panel-title {
    display: none;
  }
}

.b-navigation_show {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 25px;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-navigation_show {
    display: none;
  }
}
.b-navigation_show.m-account {
  align-items: center;
  border-bottom: 1px solid #D2D2D2;
  color: #45132C;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.b-navigation_show svg {
  background-color: #FFFFFF;
  border-right: 2px solid #45132C;
  box-sizing: content-box;
  height: 100%;
  padding: 0 12px 0 14px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, border-color;
}
.b-navigation_show.m-account svg {
  border: 0;
  padding: 0 5px 0 0;
}
.b-navigation_show-text {
  margin: 0 auto;
}
.b-navigation_show.m-account .b-navigation_show-text {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}

.b-anchors {
  margin: 0 auto;
  max-width: 940px;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .b-anchors {
    text-align: left;
  }
}
.b-anchors-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 15px;
}
@media screen and (min-width: 1024px) {
  .b-anchors-title {
    margin-bottom: 20px;
  }
}
.b-anchors-subtitle {
  font-size: 13px;
  margin-bottom: 15px;
}
@media screen and (min-width: 1024px) {
  .b-anchors-subtitle {
    margin: 0 auto 25px;
    max-width: 600px;
  }
}
@media screen and (min-width: 1024px) {
  .b-anchors.m-left .b-anchors-subtitle {
    margin: 0 0 25px;
  }
}
@media screen and (max-width: 1023px) {
  .b-anchors-list {
    margin: 0 -8px;
  }
}
.b-anchors.m-left .b-anchors-list {
  margin: 0 -8px;
}
.b-anchors-item {
  align-items: center;
  display: inline-flex;
  font-size: 15px;
  line-height: 1;
  margin: 8px;
}
.b-anchors.m-left {
  text-align: left;
}

@media screen and (min-width: 1024px) {
  .b-delivery_info_accordion {
    border: 1px solid #D2D2D2;
  }
}
.b-delivery_info_accordion .b-delivery_info_accordion-wrapper {
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .b-delivery_info_accordion-row {
    background-color: #F2F2F2;
    display: flex;
  }
}
@media screen and (max-width: 1023px) {
  .b-delivery_info_accordion-row {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(3, auto);
  }
}
@media screen and (min-width: 1024px) {
  .b-delivery_info_accordion-title {
    flex: 1 0 20%;
  }
}
@media screen and (min-width: 1024px) {
  .b-delivery_info_accordion-price {
    flex: 0 0 15%;
    padding: 0 10px;
  }
}
@media screen and (min-width: 1024px) {
  .b-delivery_info_accordion-description {
    flex: 1 1 60%;
  }
}
@media screen and (max-width: 1023px) {
  .b-delivery_info_accordion-title, .b-delivery_info_accordion-price, .b-delivery_info_accordion-description {
    grid-column: 1;
  }
}
@media screen and (max-width: 1023px) {
  .b-delivery_info_accordion-aside {
    grid-column: 2;
    grid-row: 1/-1;
  }
}
.b-delivery_info_accordion-content[aria-hidden=false] {
  border-top: 1px solid #D2D2D2;
  padding-top: 20px;
}

/*md

# b-folder_nav_list

Content pages aside navigation

```html_example
<nav class="b-folder_nav_list" aria-label="Help center menu">
	<ul class="b-folder_nav_list-items">
		<li class="b-folder_nav_list-item">
			<a class="b-folder_nav_list-link m-active" href="#">
				Customer Service
			</a>
			<ul class="b-folder_nav_list-sublist">
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Delivery &amp; Returns
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Ordering
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Contact Us
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Privacy Policy
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Terms And Conditions
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						FAQ
					</a>
				</li>
			</ul>
		</li>
		<li class="b-folder_nav_list-item">
			<a class="b-folder_nav_list-link " href="#">
				About us
			</a>
		</li>
	</ul>
</nav>
```
*/
.b-folder_nav_list {
  border-bottom: 1px solid #D2D2D2;
  border-top: 1px solid #D2D2D2;
  margin-bottom: 40px;
}
@media screen and (max-width: 1023px) {
  .b-folder_nav_list {
    border-top: 0;
    margin-top: -32px;
  }
}
.b-folder_nav_list-items {
  font-size: 16px;
  font-weight: 600;
}
.b-folder_nav_list-item {
  margin: 16px 0;
}
.b-folder_nav_list-sublist {
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}
.b-folder_nav_list-link:hover {
  color: #2F2F2F;
  text-decoration: none;
}
.b-folder_nav_list-link.m-active {
  color: #2F2F2F;
}

/*md

# b-folder_nav_tiles

## Folder navigation with top level category
Use `$content.renderSubFolders()` in folder's body field

```html_example
<div class="b-folder_nav_tiles m-top_level">
	<section class="b-folder_nav_tiles-item" aria-label="Delivery &amp; Returns">
		<a class="b-folder_nav_tiles-link" href="#">Delivery &amp; Returns</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Ordering">
		<a class="b-folder_nav_tiles-link" href="#">Ordering</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Contact Us">
		<a class="b-folder_nav_tiles-link" href="#">Contact Us</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Privacy Policy">
		<a class="b-folder_nav_tiles-link" href="#">Privacy Policy</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Terms And Conditions">
		<a class="b-folder_nav_tiles-link" href="#">Terms And Conditions</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="FAQ">
		<a class="b-folder_nav_tiles-link" href="#">FAQ</a>
	</section>
</div>
```

## Folder navigation including sub folders
Use `$content.renderSubFolders({"includeSecondLevel": true})` in folder's body field

```html_example
<div class="b-folder_nav_tiles">
	<section class="b-folder_nav_tiles-item" aria-label="Payment">
		<a class="b-folder_nav_tiles-link" href="#">Payment</a>
		<ul class="b-folder_nav_tiles-list">
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					Available Payment Methods
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					How to Pay with PayPal
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="">
					Secure Payment
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					How to Pay by Credit Card
				</a>
			</li>
		</ul>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Refunds">
		<a class="b-folder_nav_tiles-link" href="#">Refunds</a>
		<ul class="b-folder_nav_tiles-list">
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					Refund Policy
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					How to Ask for Refund
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="">
					Refund Request is Denied
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					Refund Limitations
				</a>
			</li>
		</ul>
	</section>
</div>
```
*/
.b-folder_nav_tiles {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}
.b-folder_nav_tiles-item {
  flex-basis: 33.3%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 48px;
}
@media screen and (max-width: 767px) {
  .b-folder_nav_tiles-item {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-folder_nav_tiles-item {
    flex-basis: 50%;
  }
}
.b-folder_nav_tiles.m-top_level .b-folder_nav_tiles-item {
  box-shadow: 1px 0 0 0 #D2D2D2, 0 1px 0 0 #D2D2D2, 1px 0 0 0 #D2D2D2 inset, 0 1px 0 0 #D2D2D2 inset;
  margin-bottom: 0;
  min-height: 150px;
}
@media screen and (max-width: 767px) {
  .b-folder_nav_tiles.m-top_level .b-folder_nav_tiles-item {
    min-height: 80px;
  }
}
.b-folder_nav_tiles-link:hover {
  color: #2F2F2F;
  text-decoration: none;
}
.b-folder_nav_tiles.m-top_level .b-folder_nav_tiles-link {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 12px;
}
.b-folder_nav_tiles-list {
  font-size: 14px;
  font-weight: 500;
}
.b-folder_nav_tiles .b-folder_nav_tiles-list {
  list-style: none;
  padding: 0;
}
.b-folder_nav_tiles .b-folder_nav_tiles-list_item {
  margin: 16px 0;
}

.b-contact {
  max-width: 500px;
}
.b-contact.m-width_full {
  max-width: 100%;
}
.b-contact .b-contact-head {
  margin-bottom: 40px;
}
.b-contact-subtitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  margin: 32px 0 16px;
}
@media screen and (max-width: 767px) {
  .b-contact-subtitle {
    font-size: 22px;
  }
}

.b-account_bg {
  background-color: #F5EEE4;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  margin: 0 auto;
  overflow: hidden;
  padding: 40px 20px;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .b-account_bg {
    max-width: 785px;
  }
}
.b-account_bg-logo {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .b-account_bg-logo {
    justify-content: flex-end;
  }
}
.b-account_bg-content_wrapper {
  margin: auto;
  max-width: 860px;
}
.b-account_bg-content_wrapper form {
  margin: auto;
  max-width: 667px;
}
.b-account_bg-mast {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .b-account_bg-mast {
    flex-direction: row;
    gap: 2rem;
    text-align: initial;
  }
}
.b-account_bg-mast_block {
  flex: 0 1 50%;
}
.b-account_bg-mast .b-account_benefits {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .b-account_bg-mast .b-account_benefits {
    font-size: 20px;
  }
}
.b-account_bg-mast .b-account_benefits p:last-child {
  margin: 0;
}
.b-account_bg-entice {
  display: flex;
  gap: 10px;
}
.b-account_bg-entice_block {
  align-items: center;
  color: #45132C;
  display: flex;
  flex: 0 1 33%;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  justify-content: flex-start;
  line-height: 1.1;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .b-account_bg-entice_block {
    font-size: 15px;
  }
}
.b-account_bg-entice_icon {
  align-items: flex-end;
  display: flex;
  height: 60px;
  margin-bottom: 4px;
}
.b-account_bg-entice_title {
  color: #C23D74;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .b-account_bg-entice_title {
    font-size: 18px;
  }
}
.b-account_bg-title {
  color: #45132C;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .b-account_bg-title {
    font-size: 32px;
  }
}
.b-account_bg.m-login {
  padding-top: 20px;
}
.b-account_bg::before, .b-account_bg::after {
  background-size: cover;
  content: "";
  position: absolute;
}
.b-account_bg::before {
  background: url("../images/ma/knitting.png") no-repeat;
  background-size: 85%;
  height: 115px;
  left: 0;
  top: 19px;
  width: 74px;
}
@media screen and (max-width: 767px) {
  .b-account_bg::before {
    background-size: 60%;
    top: 9px;
  }
}
.b-account_bg::after {
  background: url("../images/ma/semicircle.png") no-repeat;
  background-position-y: 100%;
  bottom: 0;
  height: 85px;
  right: 27px;
  width: 146px;
}
@media screen and (max-width: 767px) {
  .b-account_bg::after {
    background-size: 100%;
    bottom: -15px;
    height: 73px;
    right: 19px;
    width: 73px;
  }
}
.b-account_bg.m-club_register {
  background: #97D7D6;
  background-size: 515px auto;
  border-radius: initial;
  max-width: 1024px;
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .b-account_bg.m-club_register {
    border-radius: 4px;
  }
}
@media screen and (min-width: 1024px) {
  .b-account_bg.m-club_register {
    background-size: 1024px auto;
    padding: 16px;
  }
}
.b-account_bg.m-club_register::before, .b-account_bg.m-club_register::after {
  display: none;
}

.b-club_subscribe {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .b-club_subscribe {
    margin-top: 70px;
  }
}
.b-club_subscribe-title {
  color: #45132C;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  margin: 25px auto 50px;
  text-align: center;
}
.b-club_subscribe-button {
  margin-bottom: 30px;
}

.b-category_item {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: space-evenly;
  line-height: 15px;
  min-height: 90px;
  min-width: 100px;
  padding: 0 5px;
  text-align: center;
}
.b-category_item-img {
  display: block;
  filter: brightness(0) saturate(100%) invert(96%) sepia(79%) saturate(3427%) hue-rotate(321deg) brightness(100%) contrast(92%);
  margin: 0 auto 3px;
}
.b-category_item-title {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 30px;
}

body {
  overflow-y: scroll;
  pointer-events: all;
  visibility: var(--page_visibility, hidden);
}