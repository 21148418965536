/*md

# Textarea

Default textarea element

## Default

```html_example
	<textarea
		id=""
		class="b-textarea m-valid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

## Invalid

```html_example
	<textarea
		id=""
		class="b-textarea m-invalid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

*/

.b-textarea {
	appearance: none;
	border: $global-line solid $color-input-border;
	border-radius: $input-radius;
	box-shadow: none;
	color: adjust-color-to-bg($color-bg);
	cursor: text;
	display: block;
	font-family: inherit;
	font-size: 13px;
	max-width: 100%;
	min-height: 6em;
	min-width: 100%;
	padding: 10px;
	resize: none;
	transition: border-color $motion-ease;

	&:hover,
	&:active,
	&:focus {
		border-color: $color-select-border;
	}

	&-container {
		position: relative;
	}

	&-counter {
		bottom: 2px;
		color: $color-text-grey;
		font-size: 11px;
		position: absolute;
		right: 9px;
		text-align: right;
	}

	&-counter_value {
		padding-right: 4px;
		position: relative;

		&::after {
			content: '/';
			padding-left: 1px;
			position: absolute;
		}
	}

	&.m-invalid {
		border-color: $color-error-border;
	}

	&.m-no_resize {
		resize: none;
	}

	&.m-disabled,
	&[disabled] {
		background: $color-bg-shade;
		cursor: default;
		pointer-events: none;
	}
}
