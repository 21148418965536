.b-navigation_panel {
	padding: 0;
	pointer-events: auto;

	@include media(lg-up) {
		display: block;
		position: static;
		visibility: visible;
		z-index: initial;
	}

	&::after {
		@include media(lg-up) {
			display: none;
		}
	}

	.b-dialog-close {
		top: 15px;

		@include media(lg-up) {
			display: none;
		}
	}

	&-inner {
		@include g-section_holder;

		@include media(md-down) {
			background-color: $color-bg-panel;
			bottom: 0;
			height: 100%;
			left: 0;
			max-width: 310px;
			overflow-y: auto;
			padding-bottom: 20px;
			padding-top: 40px;
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition-property: visibility, transform;
			visibility: hidden;
			width: 80vw;
			z-index: z(modal);

			&.m-closed {
				transition: $motion-ease-popups;
			}

			&.m-active {
				box-shadow: $depth-3;
				transform: translateX(0);
				transition: $motion-ease-popups;
				visibility: visible;
			}
		}

		@include media(lg-up) {
			padding: 0;
		}
	}

	&-title {
		@include g-heading_4;

		display: block;
		margin-bottom: 30px;

		@include media(md-down) {
			display: none;
		}
	}
}
