/*md

# b-folder_nav_tiles

## Folder navigation with top level category
Use `$content.renderSubFolders()` in folder's body field

```html_example
<div class="b-folder_nav_tiles m-top_level">
	<section class="b-folder_nav_tiles-item" aria-label="Delivery &amp; Returns">
		<a class="b-folder_nav_tiles-link" href="#">Delivery &amp; Returns</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Ordering">
		<a class="b-folder_nav_tiles-link" href="#">Ordering</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Contact Us">
		<a class="b-folder_nav_tiles-link" href="#">Contact Us</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Privacy Policy">
		<a class="b-folder_nav_tiles-link" href="#">Privacy Policy</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Terms And Conditions">
		<a class="b-folder_nav_tiles-link" href="#">Terms And Conditions</a>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="FAQ">
		<a class="b-folder_nav_tiles-link" href="#">FAQ</a>
	</section>
</div>
```

## Folder navigation including sub folders
Use `$content.renderSubFolders({"includeSecondLevel": true})` in folder's body field

```html_example
<div class="b-folder_nav_tiles">
	<section class="b-folder_nav_tiles-item" aria-label="Payment">
		<a class="b-folder_nav_tiles-link" href="#">Payment</a>
		<ul class="b-folder_nav_tiles-list">
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					Available Payment Methods
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					How to Pay with PayPal
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="">
					Secure Payment
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					How to Pay by Credit Card
				</a>
			</li>
		</ul>
	</section>
	<section class="b-folder_nav_tiles-item" aria-label="Refunds">
		<a class="b-folder_nav_tiles-link" href="#">Refunds</a>
		<ul class="b-folder_nav_tiles-list">
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					Refund Policy
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					How to Ask for Refund
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="">
					Refund Request is Denied
				</a>
			</li>
			<li class="b-folder_nav_tiles-list_item">
				<a class="b-folder_nav_tiles-link" href="#">
					Refund Limitations
				</a>
			</li>
		</ul>
	</section>
</div>
```
*/

.b-folder_nav_tiles {
	display: flex;
	flex-wrap: wrap;
	margin-top: rh(8);

	&-item {
		flex-basis: 33.3%;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: rh(12);

		@include media(sm) {
			flex-basis: 100%;
		}

		@include media(lg) {
			flex-basis: 50%;
		}

		.b-folder_nav_tiles.m-top_level & {
			box-shadow: 1px 0 0 0 $color-divider, 0 1px 0 0 $color-divider, 1px 0 0 0 $color-divider inset, 0 1px 0 0 $color-divider inset;
			margin-bottom: 0;
			min-height: 150px;

			@include media(sm) {
				min-height: 80px;
			}
		}
	}

	&-link {
		&:hover {
			color: $color-primary;
			text-decoration: none;
		}

		.b-folder_nav_tiles.m-top_level & {
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: center;
			padding: rh(3);
		}
	}

	&-list {
		font-size: 14px;
		font-weight: 500;

		.b-folder_nav_tiles & {
			list-style: none;
			padding: 0;
		}
	}

	&-list_item {
		.b-folder_nav_tiles & {
			margin: rh(4 0);
		}
	}
}
