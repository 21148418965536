.b-grid {
	@include g-grid;

	@for $col from 2 to 13 {
		&-col_#{$col} {
			grid-column: span #{$col};
		}
	}

	@for $col from 2 to 13 {
		@each $_view in xl, lg, md, sm {
			&-col_#{$col}_#{$_view } {
				@include media(#{$_view}) {
					grid-column: span #{$col};
				}
			}
		}
	}
}
