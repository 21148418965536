.b-contact {
	max-width: 500px;

	&.m-width_full {
		max-width: 100%;
	}

	&-head {
		.b-contact & {
			margin-bottom: rh(10);
		}
	}

	&-subtitle {
		@include g-heading_3;

		margin: rh(8) 0 rh(4);

		@include media(sm) {
			font-size: 22px;
		}
	}
}
