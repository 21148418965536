/*md

# b-folder_nav_list

Content pages aside navigation

```html_example
<nav class="b-folder_nav_list" aria-label="Help center menu">
	<ul class="b-folder_nav_list-items">
		<li class="b-folder_nav_list-item">
			<a class="b-folder_nav_list-link m-active" href="#">
				Customer Service
			</a>
			<ul class="b-folder_nav_list-sublist">
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Delivery &amp; Returns
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Ordering
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Contact Us
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Privacy Policy
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						Terms And Conditions
					</a>
				</li>
				<li class="b-folder_nav_list-item">
					<a class="b-folder_nav_list-link " href="#">
						FAQ
					</a>
				</li>
			</ul>
		</li>
		<li class="b-folder_nav_list-item">
			<a class="b-folder_nav_list-link " href="#">
				About us
			</a>
		</li>
	</ul>
</nav>
```
*/

.b-folder_nav_list {
	border-bottom: 1px solid $color-divider;
	border-top: 1px solid $color-divider;
	margin-bottom: rh(10);

	@include media(md-down) {
		border-top: 0;
		margin-top: rh(-8);
	}

	&-items {
		font-size: 16px;
		font-weight: 600;
	}

	&-item {
		margin: rh(4 0);
	}

	&-sublist {
		font-size: 14px;
		font-weight: 500;
		margin-left: rh(3);
	}

	&-link {
		&:hover {
			color: $color-primary;
			text-decoration: none;
		}

		&.m-active {
			color: $color-primary;
		}
	}
}
