.b-measure_tips {
	align-items: center;
	display: flex;

	@include media(sm) {
		flex-direction: column;
	}

	&-image_wrap {
		flex-shrink: 0;
		max-width: 210px;
		width: 100%;

		@include media(md) {
			max-width: 212px;
		}

		@include media(lg-up) {
			max-width: 253px;
		}
	}

	&-image {
		@include g-image_container(_container, aspect-ratio(210, 298));

		@include media(md) {
			padding-bottom: aspect-ratio(212, 322);
		}

		@include media(lg-up) {
			padding-bottom: aspect-ratio(253, 380);
		}

		img {
			@include g-image_container(_img);
		}
	}

	&-list {
		li {
			margin-bottom: rh(6);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&-list_wrap {
		align-self: flex-start;

		@include media(md-up) {
			align-self: initial;
			margin-left: rh(5);
			max-width: 560px;
		}
	}
}
