.b-delivery_info_accordion {
	@include media(lg-up) {
		border: $global-line solid $color-divider;
	}

	& &-wrapper { //specifity added to avoid of using tag seceltor
		margin: 0;
	}

	&-row {
		@include media(lg-up) {
			background-color: $color-info-obj;
			display: flex;
		}

		@include media(md-down) {
			display: grid;
			grid-template-columns: 1fr min-content;
			grid-template-rows: repeat(3, auto);
		}
	}

	&-title {
		@include media(lg-up) {
			flex: 1 0 20%;
		}
	}

	&-price {
		@include media(lg-up) {
			flex: 0 0 15%;
			padding: 0 10px;
		}
	}

	&-description {
		@include media(lg-up) {
			flex: 1 1 60%;
		}
	}

	&-title,
	&-price,
	&-description {
		@include media(md-down) {
			grid-column: 1;
		}
	}

	&-aside {
		@include media(md-down) {
			grid-column: 2;
			grid-row: 1 / -1;
		}
	}

	&-content[aria-hidden='false'] {
		border-top: $global-line solid $color-divider;
		padding-top: 20px;
	}
}
