.b-account_bg {
	background-color: $color-bg-ma;
	border: $global-line solid $color-divider;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	margin: 0 auto;
	overflow: hidden;
	padding: 40px 20px;
	position: relative;

	@include media(lg-up) {
		max-width: 785px;
	}

	&-logo {
		display: flex;
		justify-content: center;

		@include media(lg-up) {
			justify-content: flex-end;
		}
	}

	&-content_wrapper {
		margin: auto;
		max-width: 860px;

		form {
			margin: auto;
			max-width: 667px;
		}
	}

	&-mast {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-bottom: 2rem;
		text-align: center;

		@include media(md-up) {
			flex-direction: row;
			gap: 2rem;
			text-align: initial;
		}

		&_block {
			flex: 0 1 50%;
		}

		.b-account_benefits {
			font-size: 18px;
			font-weight: 500;
			line-height: 1.25;
			margin: 0;

			@include media(md-up) {
				font-size: 20px;
			}

			p:last-child {
				margin: 0;
			}
		}
	}

	&-entice {
		display: flex;
		gap: 10px;

		&_block {
			align-items: center;
			color: $color-burgundy;
			display: flex;
			flex: 0 1 33%;
			flex-direction: column;
			font-size: 12px;
			font-weight: 500;
			justify-content: flex-start;
			line-height: 1.1;
			text-align: center;

			@include media(md-up) {
				font-size: 15px;
			}
		}

		&_icon {
			align-items: flex-end;
			display: flex;
			height: 60px;
			margin-bottom: 4px;
		}

		&_title {
			color: $color-pink;
			font-size: 14px;
			font-weight: 700;
			line-height: 1.2;
			text-transform: uppercase;

			@include media(md-up) {
				font-size: 18px;
			}
		}
	}

	&-title {
		color: $color-burgundy;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.25;
		margin-bottom: 8px;

		@include media(md-up) {
			font-size: 32px;
		}
	}

	&.m-login {
		padding-top: 20px;
	}

	&::before,
	&::after {
		background-size: cover;
		content: '';
		position: absolute;
	}

	&::before {
		background: url('../images/ma/knitting.png') no-repeat;
		background-size: 85%;
		height: 115px;
		left: 0;
		top: 19px;
		width: 74px;

		@include media(sm) {
			background-size: 60%;
			top: 9px;
		}
	}

	&::after {
		background: url('../images/ma/semicircle.png') no-repeat;
		background-position-y: 100%;
		bottom: 0;
		height: 85px;
		right: 27px;
		width: 146px;

		@include media(sm) {
			background-size: 100%;
			bottom: -15px;
			height: 73px;
			right: 19px;
			width: 73px;
		}
	}

	&.m-club_register {
		background: $color-bg-ma-club;
		background-size: 515px auto;
		border-radius: initial;
		max-width: 1024px;
		padding-top: 20px;

		@include media(md-up) {
			border-radius: $global-radius;
		}

		@include media(lg-up) {
			background-size: 1024px auto;
			padding: 16px;
		}

		&::before,
		&::after {
			display: none;
		}
	}
}
